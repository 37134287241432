import React, { useContext, useEffect, useState } from "react";
import Slider from "../../components/slider/Slider";
import "./style/home.css";
import TeamMember from "../../components/teamMember/TeamMember";
import LatestPosts from "../../components/latestPosts/LatestPosts";
import TrendingNews from "../../components/trendingNews/TrendingNews";
import { FaPeopleGroup, FaMicrophone, FaQuoteRight, FaQuoteLeft } from "react-icons/fa6";
import { AppContext } from "../../context/modeContext";
import { RiTeamFill } from "react-icons/ri";
import whoWeAre from "../../imgs/who-we-are.png";
import axios from "axios";
import { apiUrl } from "../../constants";
import OneAd from "../../components/oneAd/OneAd";
import owner from "../../imgs/owner.jpg";
import writerPresident from "../../imgs/writerPresident.jpg";

const Home = () => {
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployees = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/users/TeamWork`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setEmployees(response.data.TeamWork.TeamWork);
      } else {
        console.error("Error in response data structure");
      }
    } catch (error) {
      console.error("Error calling employees API:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <div className="container">
      <div className="trending">
        <TrendingNews />
      </div>

      <main>
        <Slider />
      </main>

      <section className="brief">
        <div className="titleAndDesc">
          <h1 style={{ color: mode === "dark" ? "white" : "black" }}>
            <FaPeopleGroup color="#2163e8" /> من نحن ؟
          </h1>
          <p>
            موقع إخباري ثقافي اجتماعي رياضي يعمل على إبراز الصورة الإيجابية
            للأشخاص ذوي الإعاقة، ويسلط الضوء على أهم الإنجازات والنجاحات
            والشخصيات الرائدة بغرض تحسين نظرة المجتمع تجاه هذه الفئة الملهمة.
          </p>
        </div>
        <div className="whoImg">
          <img src={whoWeAre} alt="من نحن" />
        </div>
      </section>

      <section className="Dpobrief">
        <div className="ownersImgs">
          <div className="whoImg">
            <img src={owner} alt="رئيس مجلس الادارة" />
            <h4 className="my-3">رئيس مجلس الادارة:<br/> د/عبدالرازق آل تركي</h4>
          </div>
          <div className="whoImg">
            <img src={writerPresident} alt="رئيس التحرير" />
            <h4 className="my-3">رئيس التحرير:<br /> د/ سيد سعد</h4>
          </div>
        </div>
        <div className="titleAndDesc">
          <h3 style={{ color: mode === "dark" ? "white" : "black" }}>
            <FaMicrophone color="#2163e8" size={35} /> كلمة رئيس مجلس الإدارة
          </h3>
          <p>
          <FaQuoteRight color="#2163e8" className="m-2" size={30} />
            من قلوب مملوءة بالصدق تجاه أشخاص يصنعون المستحيل، ويبهرون العالم
            بقدراتهم العظيمة، ولم يجعلوا من الإعاقة أداة للفشل، بل كانت وسيلة
            للوصول إلى آفاق النجاح المستديم.<br />
            من عقول تستشعر الخير في مجتمع يؤمن بأن الأشخاص ذوي الإعاقة
            شركاء أساسين في النهوض به، لذا فقد أسسنا هذه المنصة الرقمية لتكون
            معبراً حقيقياً عن طموحات وتطلعات أشخاص يحلمون بمستقبل مشرق، كما
            تعمل هذه المنصة على رصد النجاحات وكذلك تسليط الضوء على الشخصيات
            المتميزة في عالم ذوي الإعاقة، وذلك بغرض تحسين نظرة المجتمعات تجاه
            هذه الفئة المميزة والعمل على منحهم دوراً كبيراً في بناء أوطانهم.
            فإن منصة Dpo-News الإخبارية هي منبر إعلامي للتعبير عن آراء الأشخاص
            ذوي الإعاقة وتسليط الضوء على أبرز الأخبار التي تمس هذه الفئة
            الملهمة وكذلك متابعة أهم أنشطة المنظمات العاملة في مجال ذوي الإعاقة
            في جميع أنحاء العالم.<br />
           راجين من الله تعالى أن يوفقنا في تقديم محتوى إعلامي رائع
            يحقق لنا الريادة كأول موقع عربي يرصد أخبار العاملين في مجال ذوي الإعاقة.
            <FaQuoteLeft color="#2163e8" className="m-2" size={30} />
          </p>
        </div>
      </section>

      <section className="team container-fluid">
        <h3 style={{ color: mode === "dark" ? "white" : "black" }}>
          <RiTeamFill color="#2163e8" /> فريق العمل
        </h3>
        {loading ? (
          <h4 style={{ color: mode === "dark" ? "white" : "black" }}>جار التحميل</h4>
        ) : (
          <div className="members row">
            {employees.map((emp, index) => (
              <div className="col-sm-3 mx-auto my-3" key={index}>
                <TeamMember name={emp.name} position={emp.position} image={emp.image} />
              </div>
            ))}
          </div>
        )}
      </section>

      <section className="latestSection">
        <LatestPosts />
      </section>

      <OneAd />
    </div>
  );
};

export default Home;
