import React, { useContext, useState } from "react";
import Input from "../../components/input/Input";
import "./style/login.css";
import { AppContext } from "../../context/modeContext";
import axios from "axios";
import { apiUrl } from "./../../constants/index";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { mode } = useContext(AppContext);
  const [message, setMessage] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  // const role = localStorage.getItem("role")

  const body = {
    username: userName,
    password: password,
  };
  // call login api
  const login = async () => {
    console.log("login function");
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/api/v1/users/login`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("response: ", response);
      if (response.status === 200) {
        setLoading(false);
        setErrorMsg(null);
        setMessage("تم تسجيل الدخول بنجاح");
        localStorage.setItem("token", response.data.token.token);
        localStorage.setItem("role", response.data.token.role);
        if(response.data.token.role === "author"){
          navigate("/posts-dashboard")
        }else if(response.data.token.role === "admin"){
          navigate("/admin-dashboard")
        }else {
          navigate("/")
        }
      } else {
        setLoading(false);
        setMessage(null);
        setErrorMsg("هناك خطأ في اسم المستخدم او كلمة المرور");
      }
    } catch (error) {
      setLoading(false);
      console.error("error calling API", error);
      setErrorMsg("هناك خطأ في اسم المستخدم او كلمة المرور");
    }
  };

  return (
    <div className="login rounded">
      <div className="loginTitle">
        <h2 style={{ color: mode === "dark" ? "white" : "black" }}>
          تسجيل الدخول
        </h2>
      </div>
      <div className="inputs">
        <Input
          value={userName}
          placeholder={"ادخل اسم المستخدم الخاص بك"}
          onchange={(e) => setUserName(e.target.value)}
          bgcolor={"#191a1f"}
          type={"text"}
        />
        <Input
          value={password}
          placeholder={"ادخل كلمة المرور"}
          onchange={(e) => setPassword(e.target.value)}
          bgcolor={"#191a1f"}
          type={"password"}
        />
      </div>
      <div className="errors my-5 w-75 mx-auto text-center">
        {message !== null && <Alert variant={"success"}>{message}</Alert>}
        {errorMsg !== null && <Alert variant={"danger"}>{errorMsg}</Alert>}
      </div>
      <div className="submit">
        <button onClick={login}>{loading ? "....تحميل" : "تسجيل"}</button>
      </div>
    </div>
  );
};

export default Login;
