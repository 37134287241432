import React, { useContext, useEffect, useState } from "react";
import "./style/adsDisPage.css";
import { FaCalendarAlt } from "react-icons/fa";
import LatestPosts from "../../../components/latestPosts/LatestPosts";
import { AppContext } from "../../../context/modeContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../../constants";

const AdsDisPage = () => {
  const [ads, setAds] = useState([]);
  const { mode } = useContext(AppContext);
  const navigate = useNavigate();

  const getAds = async () => {
    console.log("get dis ads");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/disabilityAds`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("ads response", response);
      setAds(response.data.data.DisabilityAds);
    } catch (error) {
      console.error("error calling api", error);
    }
  };

  useEffect(() => {
    getAds();
  }, []);
  return (
    <div className="adsPage container">
      <main className="adsPageTitle">
        <h1>إعلانات لذوي الإعاقة</h1>
      </main>

      <div className="adsCards my-5">
        <div className="row my-3">
          {ads.map((ad, index) => (
            <div
              key={index}
              className="col-sm-3 my-3 mx-auto adsCard"
              style={{ backgroundColor: mode === "dark" ? "#191a1f" : "white" }}
            >
              <div className="cardTop">
                <span>{ad.beneficiaryGroup}</span>
                <span>{ad.ageGroup}</span>
              </div>
              <div className="adsImg">
                <img
                  src={ad.image}
                  alt={ad.productName}
                />
              </div>
              <div className="adsDate">
                <FaCalendarAlt color="#3D4B97" />
                <h6 className="date">{ad.createdAt.slice(0,10)}</h6>
              </div>
              <div className="adsName">
                <h3 onClick={() => navigate(`/disabled-ads/${ad._id}`)}>
                  {ad.productName}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="latestNews">
        <LatestPosts />
      </div>
    </div>
  );
};

export default AdsDisPage;
