import axios from "axios";
import React, { useEffect, useState } from "react";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";
import "./style/trend.css";

const TrendingNews = () => {
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getNews = async () => {
    // const urls = [
    //   "http://api.mediastack.com/v1/news?access_key=f4f2b352634f58b68ee8b8f795117180&countries=sa",
    //   "http://api.mediastack.com/v1/news?access_key=f4f2b352634f58b68ee8b8f795117180&countries=eg",
    //   // "https://newsapi.org/v2/top-headlines?country=ae&apiKey=9b6ae5dcff544e0cb4cc1e6815f2845f",
    //   // "https://newsapi.org/v2/sources?language=ar&apiKey=9b6ae5dcff544e0cb4cc1e6815f2845f",
    // ];

    try {
      const response = await axios.get("http://api.mediastack.com/v1/news?access_key=f4f2b352634f58b68ee8b8f795117180&countries=eg,kw&language=ar")
      // const allNews = responses.flatMap((response) => response);
      // setNews(allNews);
      console.log("all news:",  response.data.data);
      setNews(response.data.data)
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    if (news.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [news]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? news.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="newsApi container">
      <div className="newsContent">
        <div className="right">
          <div >
            <h5 className="trendingTitle">أحدث الاخبار</h5>
          </div>
          <div className="newsText">
            {news.length > 0 ? (
              <a href={news.url}>{news[currentIndex]?.title}</a>
            ) : (
              <h6>لا يوجد اخبار حاليا</h6>
            )}
          </div>
        </div>
        <div className="transform">
          <span onClick={handleNext}>
            <GrFormNextLink size={25} />
          </span>
          <span onClick={handlePrevious}>
            <GrFormPreviousLink size={25} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TrendingNews;
