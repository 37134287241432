import React, { useContext, useState } from "react";
import Input from "../../components/input/Input";
import "./style/createAuthor.css";
import { AppContext } from "../../context/modeContext";
import axios from "axios";
import { apiUrl } from "../../constants";
import { useNavigate } from "react-router-dom";

const CreateAuthor = () => {
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("user");
  const [image, setImage] = useState("");
  const [inTeamWork, setInTeamWork] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleUpload = async (file) => {
    console.log("handle file upload");
    console.log("image file: ", file);

    // Create FormData and append the file
    const formData = new FormData();
    formData.append("file", file); // 'file' should match the key expected by the backend

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/upload/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("img response", response.data);
      setImage(response.data.file.path); // Update this based on your backend's response
    } catch (error) {
      console.error("error uploading image", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file); // Set the selected file
    handleUpload(file); // Upload the file immediately
  };

  const createUser = async () => {
    console.log("create user");
    setLoading(true);
    if (username.length === 0) {
      setLoading(false);
      setError("يجب إدخال اسم المستخدم الخاص بتسجيل الدخول");
    } else if (position.length === 0) {
      setLoading(false);
      setError("يجب إدخال الوظيفة");
    } else if (image.length === 0) {
      setLoading(false);
      setError("يجب إدخال صورة");
    } else if(name.length === 0){
      setLoading(false)
      setError("يجب إدخال الاسم")
    } else if(email.length === 0){
      setLoading(false)
      setError("يجب إدخال البريد اللإلكتروني")
    } else {
      setError("")
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/users/create`,
          {
            name,
            password,
            username,
            image,
            position,
            email,
            inTeamWork,
            role,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("create response", response);
        if (response.data) {
          setLoading(false);
          alert("تمت الإضافة بنجاح");
          navigate("/employees-view")
        } else {
          setLoading(false);
          alert("خطأ في الإضافة حاول مرة أخرى");
        }
      } catch (error) {
        setLoading(false);
        alert(error.response.data.error);
        console.error("error fetching create user", error);
      }
    }
  };

  return (
    <div className="createPost container">
      <h1 style={{ color: mode === "dark" ? "white" : "black" }}>إنشاء موظف</h1>
      <div className="formPost">
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              اسم الموظف
            </label>
            <Input
              type={"text"}
              value={name}
              onchange={(e) => setName(e.target.value)}
              placeholder={" مثال: احمد محمد"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
            >{`اسم الموظف(لتسجيل الدخول)`}</label>
            <Input
              type={"text"}
              value={username}
              onchange={(e) => setUserName(e.target.value)}
              placeholder={"اسم الموظف"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الوظيفة
            </label>
            <Input
              type={"text"}
              value={position}
              onchange={(e) => setPosition(e.target.value)}
              placeholder={"عضو - مدير - مسئول"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              العنوان الوظيفي
            </label>
            <select
              className="selectt"
              name="category"
              id="category"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            >
              <option value={""}>اختر</option>
              <option value={"admin"}>مدير</option>
              <option value={"author"}>مؤلف</option>
              <option value={"user"}>عضو في الفريق</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              كلمة المرور
            </label>
            <Input
              type={"password"}
              value={password}
              onchange={(e) => setPassword(e.target.value)}
              placeholder={"كلمة المرور"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
              className="d-block "
            >
              يعرض في فريق العمل ؟
            </label>
            <select
              className="selectt"
              name="category"
              id="category"
              value={inTeamWork}
              onChange={(e) => setInTeamWork(e.target.value)}
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            >
              <option value={true}>نعم</option>
              <option value={false}>لا</option>
            </select>
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              البريد الإلكتروني
            </label>
            <Input
              type={"email"}
              value={email}
              onchange={(e) => setEmail(e.target.value)}
              placeholder={"البريد الإلكتروني"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الصورة
            </label>
            <Input
              type={"file"}
              onchange={handleFileChange}
              placeholder={" صورة الموظف"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>
      </div>
      {error.length > 0 && <h4 className="text-danger text-center my-3 mx-auto">{error}</h4>}
      <div className="createBtn">
        <button onClick={createUser}>
          {loading ? "جاري الإضافة" : "إضافة"}
        </button>
      </div>
    </div>
  );
};

export default CreateAuthor;
