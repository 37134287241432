import React, { useContext, useState } from "react";
import "./style/contact.css";
import Input from "../../components/input/Input";
import { AppContext } from "../../context/modeContext";
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [reqBody, setReqBody] = useState("");
  const { mode } = useContext(AppContext);

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      phone: phone,
      email: email,
      message: reqBody,
    };

    emailjs.send(
      'service_6wchglr', // replace with your EmailJS service ID
      'template_u3xxhmg', // replace with your EmailJS template ID
      templateParams,
      'YOUR_USER_ID' // replace with your EmailJS user ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Your message has been sent successfully!');
    }, (error) => {
      console.log('FAILED...', error);
      alert('Failed to send your message. Please try again later.');
    });

    // Clear form fields after submission
    setPhone("");
    setEmail("");
    setReqBody("");
  };

  return (
    <div className="container contactus-container">
      <div className="contactTitle">
        <h1 style={{color: mode === "dark" ? "white" : "black"}}>تواصل معنا</h1>
        <p>
          يرجى إدخال رقم الهاتف والبريد الإلكتروني والرسالة، وسيتم التواصل معكم
          {" "}
        </p>
      </div>

      <form className="formPost" onSubmit={sendEmail}>
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              رقم الهاتف
            </label>
            <Input
              type={"text"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={"********966+"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              البريد الإلكتروني
            </label>
            <Input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"name****@gmail.com"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الرسالة
            </label>
            <div className="area">
              <textarea
                value={reqBody}
                onChange={(e) => setReqBody(e.target.value)}
                placeholder="تفاصيل الرسالة ......."
                style={{
                  backgroundColor: "rgba(33, 99, 232, 0.2)",
                  color: mode === "dark" ? "white" : "black",
                }}
              />
            </div>
          </div>
        </div>

        <div className="createBtn">
          <button type="submit">إرسال</button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
