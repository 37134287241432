import React from "react";
import "./style/footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../imgs/logo.svg";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer bg-dark">
      <Container>
        <Row className="footer-row">
          <Col className="my-2 mx-auto">
            <div className="summary">
              <img src={logo} alt="logo" />
              <p className="summary-paragraph">
                موقع إخباري ثقافي اجتماعي رياضي يعمل على إبراز الصورة الإيجابية
                للأشخاص ذوي الإعاقة، ويسلط الضوء على أهم الإنجازات والنجاحات
                والشخصيات الرائدة بغرض تحسين نظرة المجتمع تجاه هذه الفئة
                الملهمة.
              </p>
            </div>
          </Col>
          <Col className="my-2 mx-auto">
            <div className="links">
              <h4>اهم الصفحات</h4>
              <Link className="pagesLinks" to={"/news/local"}>
                أخبار محلية
              </Link>
              <Link className="pagesLinks" to={"/news/arab"}>
                أخبار دولية
              </Link>
              <Link className="pagesLinks" to={"/news/international"}>
                أخبار عالمية
              </Link>
              <Link className="pagesLinks" to={"/news/sports"}>
                أخبار رياضية
              </Link>
              <Link className="pagesLinks" to={"/news/Cultural"}>
                أخبار ثقافية
              </Link>
              <Link className="pagesLinks" to={"/talks"}>
                {" "}
                كلمات رواد التنمية
              </Link>
              <Link className="pagesLinks" to={"/news/inspiringPersonalities"}>
                شخصيات ملهمة
              </Link>
              <Link className="pagesLinks" to={"/disabled-people-ads"}>
                اعلانات ذوي الاعاقة
              </Link>
              <Link className="pagesLinks" to={"/"}>
                أخبار منظمات ذوي الاعاقة
              </Link>
            </div>
          </Col>
          <Col className="my-2 mx-auto">
            <div className="contact">
              <h4>طرق التواصل</h4>
              <div className="phone">
                <FaPhone size={25} color="rgba(255, 255, 255, 0.719)" />
                <span>
                  <a
                    href="https://wa.me/+966543088666"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="تواصل علي الواتس اب"
                  >
                    966543088666+
                  </a>
                </span>
              </div>
              <div className="phone">
                <MdEmail size={25} color="rgba(255, 255, 255, 0.719)" />
                <span>
                  <a href="mailto:newsdpo@gmail.com">newsdpo@gmail.com</a>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
