import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './style/specialAdsDis.css'
import LatestPosts from '../../../components/latestPosts/LatestPosts'
import { AppContext } from '../../../context/modeContext'
import axios from 'axios'
import { apiUrl } from '../../../constants'

const SpcialAdsDis = () => {
  const [specialAd, setSpecialAd] = useState({
    name: "سرير كهربائي",
    date: "20-4-2024",
    image: "https://hayat.tawasal.org.sa/sites/default/files/styles/large/public/2024-03/WhatsApp%20Image%202024-03-24%20at%2010.57.27.jpeg?itok=fgBoS2uU",
    type: "حركية",
    segmentation: "الكل",
    productStatus: "مستعمل",
    adsType: "للتبرع",
    phone:"01061472185"
  })
  const {mode} = useContext(AppContext)
  const {adId} = useParams()
  console.log(adId);
  

  const getAd = async () => {
    console.log("get ad");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/disabilityAds/${adId}`)
      console.log("ad is", response);
      if(response.data){
        setSpecialAd(response.data.DisabilityAd)
      }
    } catch (error) {
      console.error("error calling ad api")
    }
  }

  useEffect(() => {
    getAd()
  },[])
  return (
    <div className='adTitle container'>
      <div className="title">
        <h1>{specialAd.productName}</h1>
      </div>
      <div className="imgSpecificAds">
        <img src={specialAd.image} alt={specialAd.name} />
      </div>
      <div className="disType">
        <h3 style={{color: mode === "dark" ? "white" : "black"}}>نوع الإعاقة</h3>
        <h5>{specialAd.beneficiaryGroup}</h5>
      </div>
      <div className="disType">
        <h3 style={{color: mode === "dark" ? "white" : "black"}}>الفئة العمرية</h3>
        <h5>{specialAd.ageGroup}</h5>
      </div>
      <div className="disType">
        <h3 style={{color: mode === "dark" ? "white" : "black"}}>الدولة</h3>
        <h5>{specialAd.country}</h5>
      </div>
      <div className="disType">
        <h3 style={{color: mode === "dark" ? "white" : "black"}}>التفاصيل</h3>
        <h5>{specialAd.description}</h5>
      </div>
      <div className="disType">
        <h3 style={{color: mode === "dark" ? "white" : "black"}}>رقم الهاتف</h3>
        <h5>{specialAd.phone}</h5>
      </div>

      <div className="latest-posts">
        <LatestPosts/>
      </div>
    </div>
  )
}

export default SpcialAdsDis
