import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import "./style/talksTable.css";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { AppContext } from "../../context/modeContext";
import axios from "axios";
import { apiUrl } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";

const TalksTable = ({ columns, values,onClickDelete, onClickUpdate }) => {
    const {mode} = useContext(AppContext)
    const token = localStorage.getItem("token");
    const {id} = useParams() 
    const navigate = useNavigate()

    const deleteTalk = async (id) => {
      console.log("delete talk function");
      try {
        const response = await axios.delete(
          `${apiUrl}/api/v1/pioneerWords/delete-pioneerWord/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("delete response", response);
        if (response.data) {
          alert("تم حذف الموظف");
          window.location.reload();
        }else {
          console.log("error in response");
          
        }
      } catch (error) {
        console.error("error call deleting", error);
      }
    };
  return (
    <div className="adminTable">
      <Table striped bordered hover variant={mode === "dark" ? "dark" : "light"}>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th id="th" key={index}>
                <h6>{col}</h6>
              </th>
            ))}
          </tr>
        </thead>
        {values.length > 0 && (
          <tbody>
          {values.map((val) => {
            return (
              <tr key={val._id}>
                <td>{val.name}</td>
                <td>{val.body}</td>
                <td >
                  <img className="tableImg" src={val.image} alt="صورة رائد التنمية" />
                </td>
                <td>
                  <div className="icons">
                    <MdDelete size={25} className="delIcon" onClick={() => {
                      deleteTalk(val._id);
                      onClickDelete();
                       }}/>
                    {/* <FaEdit size={25} className="updIcon" onClick={() => {
                      onClickUpdate()
                      navigate(`/update-talks/${val._id}`)
                    }}/> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
        )}
        {values.length === 0 && " لا يوجد كلمات "}
      </Table>
    </div>
  );
};

export default TalksTable;
