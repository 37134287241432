import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "./../../constants/index";
import "./style/ad.css";

const OneAd = () => {
  const [ads, setAds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getAds = async () => {
    console.log("get ads");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/companyAds`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("ads response", response);
      setAds(response.data.data.companyAds);
    } catch (error) {
      console.error("error calling ads api", error);
    }
  };

  useEffect(() => {
    getAds();
  }, []);

  useEffect(() => {
    if (ads.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [ads]);

  return (
    <div>
      {/*<h3 className="my-5 mx-auto text-center ads-title" >اعلانات ممولة</h3>*/}
      <div className="ad-img my-3 mx-auto d-flex justify-content-center align-items-center w-100">
        <img
          src={ads[currentIndex]?.image}
          alt={ads[currentIndex]?.companyName}
        />
      </div>
    </div>
  );
};

export default OneAd;
